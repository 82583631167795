import React from 'react'
import { Link } from 'gatsby'

export default class PDFsNav extends React.Component {
  render() {
    const NL = ({ to, children }) => (
      <li className="nav-item">
        <Link
          to={to}
          className={`outline-primary nav-link ${
            to == this.props.path ? 'active' : ''
          }`}
        >
          {children}
        </Link>
      </li>
    )
    return (
      <nav className="d-flex flex-md-row-reverse flex-lg-column justify-content-around align-items-center">
        <ul className="nav nav-tabs justify-content-center my-2">
          <NL to="/resources/pdfs/getting-to-know-4-group/">
            Getting to know the 4-group Number Patterns
          </NL>
          <NL to="/resources/pdfs/fun-pages/">Fun Pages</NL>
          <NL to="/resources/pdfs/posters/">Strategy Posters</NL>
        </ul>
      </nav>
    )
  }
}
